



























































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { DeviceFormValues } from '@/app/models/device';
import ValidationMixin from '@/app/common/mixins/validationMixin';
import FormValidationMixin from '@/app/common/mixins/formValidationMixin';
import devicesServices from '@/app/services/devices-services';
import { namespace } from 'vuex-class';
import ModalMixin from '@/app/common/mixins/modalMixin';
const device = namespace('device');
@Component
export default class ModalDeviceForm extends Mixins(
  ValidationMixin,
  FormValidationMixin,
  ModalMixin
) {
  form = new DeviceFormValues();
  dialog = false;
  valid = true;
  editMode = false;
  loading = false;

  @device.Action
  loadDevices!: () => Promise<void>;

  actionModal(): void {
    const form = this.$refs.form as Vue & { validate: () => boolean };
    if (!form.validate()) return;
    this.addDevice();
  }

  addDevice(): void {
    this.loading = true;
    devicesServices
      .add(this.form)
      .then(() => {
        this.loading = false;
        this.$toast.success('Dispositivo Agregado Correctamente');
        this.loadDevices();
        this.close();
      })
      .catch((err) => {
        this.loading = false;
        if (err.response?.status === 400) {
          this.openModalError(err.response);
          return;
        }
        this.$toast.error('Ups, Ocurrió un error, intente nuevamente');
      });
  }

  open(): void {
    this.dialog = true;
  }

  close(): void {
    this.dialog = false;
  }
}
