<template>
  <div class="text-center">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'CustomProgressCircular',
};
</script>

<style scoped></style>
