









import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DevicesList from '@/features/devices/devices-list/DevicesList.vue';
import DevicesPaginate from '@/features/devices/devices-paginate/DevicesPaginate.vue';
import DevicesHeader from '@/features/devices/devices-header/DevicesHeader.vue';
import { FormDeviceModal } from '@/app/models/modal';
import ModalDeviceForm from '@/features/devices/_components/modals/modal-device-form/ModalDeviceForm.vue';
const device = namespace('device');
@Component({
  components: { ModalDeviceForm, DevicesHeader, DevicesPaginate, DevicesList },
})
export default class DevicesView extends Vue {
  @device.Mutation
  resetState!: () => void;
  @device.Mutation
  setModalDeviceForm!: (modal: FormDeviceModal) => void;

  mounted(): void {
    const modal = this.$refs['modal-device-form'] as Vue & {
      open: () => void;
    };
    this.setModalDeviceForm(modal);
  }
  destroyed(): void {
    this.resetState();
  }
}
