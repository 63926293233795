






































































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import CustomMessage from '@/app/common/components/custom-message/CustomMessage.vue';
import FormatMixin from '@/app/common/mixins/formatMixin';
import { namespace } from 'vuex-class';
import { Device } from '@/app/models/device';
import { GLOBAL_LINK_STATUS_TYPE } from '@/app/common/constants/link-status-type';
import ModalDeviceDetails from '@/features/devices/_components/modals/modal-device-details/ModalDeviceDetails.vue';
import ModalUploadFile from '@/features/devices/_components/modals/modal-upload-file/ModalUploadFile.vue';
import ModalDeleteDevice from '@/features/devices/_components/modals/modal-delete-device/ModalDeleteDevice.vue';
import devicesServices from '@/app/services/devices-services';
import ModalMixin from '@/app/common/mixins/modalMixin';
const device = namespace('device');
const snackbar = namespace('snackbar');
@Component({
  components: { ModalDeleteDevice, ModalUploadFile, ModalDeviceDetails, CustomMessage },
})
export default class DevicesTable extends Mixins(FormatMixin, ModalMixin) {
  linkStatus = GLOBAL_LINK_STATUS_TYPE;
  target: string | null = null;
  /******************* Start - Vuex *******************/
  @device.Getter
  devices!: Device[];
  @device.Getter
  searchMode!: boolean;
  @device.Action
  loadDevices!: () => Promise<void>;

  @snackbar.Mutation
  setSnackbar!: (val: boolean) => void;
  @snackbar.Mutation
  setKnowledge!: (val: number) => void;
  @snackbar.Mutation
  resetState!: () => void;
  /******************* End - Vuex *******************/

  openModalDeviceDetails(device: Device): void {
    const modal = this.$refs['modal-device-details'] as Vue & {
      open: (device: Device) => void;
    };
    modal.open(device);
  }
  openModalUploadFile(device: Device): void {
    const modal = this.$refs['modal-upload-file'] as Vue & {
      open: (device: Device) => void;
    };
    modal.open(device);
  }
  openModalDeleteDevice(device: Device): void {
    const modal = this.$refs['modal-delete-device'] as Vue & {
      open: (device: Device) => void;
    };
    modal.open(device);
  }
  downloadFile(device: Device): void {
    this.target = device._id;
    devicesServices
      .getUrl(device._id)
      .then((res) => {
        this.setSnackbar(true);
        this.onDownloadFile(res.url, 'Archivo.xlsx', this.setKnowledge).then(() => {
          setTimeout(() => {
            this.resetState();
            this.target = null;
          }, 1500);
        });
      })
      .catch((err) => {
        this.target = null;
        if (err.response?.status === 400) {
          this.openModalError(err.response);
          return;
        }
        if (err.response?.status === 404) {
          this.$toast.error('No existe un archivo para descargar');
          return;
        }
        this.$toast.error('Ups, Ocurrió un error, intente nuevamente');
      });
  }

  onDownloadFile(
    url: string,
    filename: string,
    setKnowledge?: (val: number) => void
  ): Promise<void> {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.addEventListener(
        'progress',
        function (evt) {
          if (evt.lengthComputable) {
            const percent = evt.loaded / evt.total;
            const knowledge = Math.round(percent * 100);
            if (setKnowledge) {
              setKnowledge(knowledge);
            }
          }
        },
        false
      );
      xhr.onload = function () {
        if (this.status === 200) {
          const myBlob = this.response;
          const url = window.URL.createObjectURL(new Blob([myBlob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}`);
          document.body.appendChild(link);
          link.click();
          resolve();
        }
      };
      xhr.send();
    });
  }
}
