
































import {Component, Vue} from 'vue-property-decorator';
import CustomMainTable from '@/app/common/components/custom-main-table/CustomMainTable.vue';
import CustomProgressCircular from '@/app/common/components/custom-progress-circular/CustomProgressCircular.vue';
import CustomMessage from '@/app/common/components/custom-message/CustomMessage.vue';
import {namespace} from 'vuex-class';
import {Device} from '@/app/models/device';
import {ErrorHandler} from '@/app/models/error';
import DevicesTable from '@/features/devices/devices-table/DevicesTable.vue';

const device = namespace('device');
@Component({
  components: { DevicesTable, CustomMessage, CustomProgressCircular, CustomMainTable },
})
export default class DevicesList extends Vue {
  /******************* Start - Vuex *******************/
  @device.Getter
  devices!: Device[];
  @device.Getter
  searchMode!: boolean;
  @device.Getter
  initialLoading!: boolean;
  /* @device.Getter
  modalFormBankRef!: FormBankModal | null;*/
  @device.Getter
  error!: ErrorHandler;

  @device.Action
  loadDevices!: () => Promise<void>;
  @device.Action
  searchEnd!: () => void;
  /******************* End - Vuex *******************/

  created(): void {
    this.loadDevices();
  }
}
