


















































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { Device } from '@/app/models/device';
import FormatMixin from '@/app/common/mixins/formatMixin';
import { GLOBAL_LINK_STATUS_TYPE } from '@/app/common/constants/link-status-type';
@Component
export default class ModalDeviceDetails extends Mixins(FormatMixin) {
  dialog = false;
  device: Device | null = null;

  open(device: Device | null): void {
    if (!device) return;
    this.device = device;
    /*    this.device.created = this.formatDateLocal(this.device.created);
    this.device.updated = this.formatDateLocal(this.device.updated);*/
    this.device.status = GLOBAL_LINK_STATUS_TYPE[device.linkStatus];
    this.dialog = true;
    this.device.credentials.privateKeyNative = '';
    const tempArrPrivateKey = this.device.credentials.privateKey.split('\n');

    for (let i = 0; i < tempArrPrivateKey.length - 1; i++) {
      let tempString = '';

      if (tempArrPrivateKey.length - 2 === i) {
        tempString = JSON.stringify(tempArrPrivateKey[i] + '\n') + ';' + '\n';
      } else {
        tempString = JSON.stringify(tempArrPrivateKey[i] + '\n') + ' \\' + '\n';
      }
      this.device.credentials.privateKeyNative += tempString;
    }

    this.device.credentials.certificatePemNative = '';
    const tempArrCertificatePem = this.device.credentials.certificatePem.split('\n');
    for (let i = 0; i < tempArrCertificatePem.length - 1; i++) {
      let tempString = '';
      if (tempArrCertificatePem.length - 2 === i) {
        tempString = JSON.stringify(tempArrCertificatePem[i] + '\n') + ';' + '\n';
      } else {
        tempString = JSON.stringify(tempArrCertificatePem[i] + '\n') + ' \\' + '\n';
      }
      this.device.credentials.certificatePemNative += tempString;
    }
    this.dialog = true;
  }
  onCopy(key: string): void {
    try {
      if (!this.device) return;
      const message = key === 'private' ? 'private key' : 'certificate key';
      const textCopy =
        key === 'private'
          ? this.device.credentials.privateKey
          : this.device.credentials.certificatePem;

      navigator.clipboard.writeText(textCopy);
      this.$toast.success(`Se copio el ${message} al portapapeles`);
    } catch (e) {
      this.$toast.error('Ha ocurrido un error');
    }
  }
  onCopyNative(key: string): void {
    try {
      if (!this.device) return;
      const message = key === 'privateNative' ? 'private key Native' : 'certificate key Native';
      const textCopy =
        key === 'privateNative'
          ? this.device.credentials.privateKeyNative
          : this.device.credentials.certificatePemNative;

      navigator.clipboard.writeText(textCopy);
      this.$toast.success(`Se copio el ${message} al portapapeles`);
    } catch (e) {
      this.$toast.error('Ha ocurrido un error');
    }
  }
  onCopyMacAddress() {
    try {
      if (!this.device) return;
      navigator.clipboard.writeText(this.device.macAddress);
      this.$toast.success(`Se copio el Mac Address al portapapeles`);
    } catch (e) {
      this.$toast.error('Ha ocurrido un error');
    }
  }
  close(): void {
    this.dialog = false;
  }
}
