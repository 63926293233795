import { Zone } from '@/app/models/zone';
import { Space } from '@/app/models/space';
import { Nac } from '@/app/models/nac';
import { CredentialModel } from '@/app/models/credential';

export interface Device {
  status: string;
  alarmStatus: string;
  linkStatus: 'unlinked' | 'linked';
  type: string;
  model: string;
  password: string;
  version: string;
  _id: string;
  name: string;
  description: string;
  awsCredentialId: string;
  macAddress: string;
  created: string;
  updated: string;
  location: Location;
  spaceId: string;
  zones: Zone[];
  nacs: Nac[];
  credentials: CredentialModel;
  space: Space;
}

export interface Location {
  lat: number;
  lng: number;
}

export class DeviceFormValues {
  _id?: string = undefined;
  name = '';
  description = '';
  constructor(device?: DeviceFormValues) {
    Object.assign(this, device);
    /*if (bank) {
      this._id = bank._id;
      this.name = bank.name;
    }*/
  }
}
