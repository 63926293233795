



























import { Component, Mixins } from 'vue-property-decorator';
import { Device } from '@/app/models/device';
import { namespace } from 'vuex-class';
import devicesServices from '@/app/services/devices-services';
import ModalMixin from '@/app/common/mixins/modalMixin';
const device = namespace('device');
@Component
export default class ModalDeleteDevice extends Mixins(ModalMixin) {
  device: Device | null = null;
  loading = false;
  dialog = false;

  @device.Action
  loadDevices!: () => Promise<void>;

  open(device: Device): void {
    this.device = device;
    this.dialog = true;
  }

  deviceDelete(): void {
    if (!this.device) return;
    this.loading = true;
    devicesServices
      .delete(this.device._id)
      .then(() => {
        this.$toast.success('Dispostivo eliminado correctamente');
        this.loadDevices();
        this.loading = false;
        this.close();
      })
      .catch((err) => {
        this.loading = false;
        if (err.response?.status === 400) {
          this.openModalError(err.response);
          return;
        }
        this.$toast.error('Ups, Ocurrió un error, intente nuevamente');
      });
  }

  close(): void {
    this.dialog = false;
    this.device = null;
  }
}
