



















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Pagination } from '@/app/models/paginate';
const device = namespace('device');
@Component
export default class DevicesPaginate extends Vue {
  /******************* Start - Vuex *******************/
  @device.Getter
  initialLoading!: boolean;
  @device.Getter
  pagination!: Pagination | null;
  @device.Mutation
  setPaginate!: (number: number) => void;
  @device.Action
  loadDevices!: () => Promise<void>;
  /******************* End - Vuex *******************/
  onChangePaginate(val: number): void {
    this.setPaginate(val);
    this.loadDevices();
  }
}
