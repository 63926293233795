













































import { Component, Vue } from 'vue-property-decorator';
import SearchDevice from '@/features/devices/_components/search-device/SearchDevice.vue';
import { namespace } from 'vuex-class';
import { FilterDeviceModal, FormDeviceModal } from '@/app/models/modal';
import ModalFilterDevices from '@/features/devices/_components/modals/modal-filter-devices/ModalFilterDevices.vue';
const device = namespace('device');
@Component({
  components: { ModalFilterDevices, SearchDevice },
})
export default class DevicesHeader extends Vue {
  @device.Getter
  initialLoading!: boolean;
  @device.Getter
  modeFilter!: boolean;
  @device.Getter
  modalFormDeviceRef!: FormDeviceModal | null;
  @device.Getter
  modalFilterDeviceRef!: FilterDeviceModal | null;

  @device.Mutation
  setModalFilterDevice!: (modal: FilterDeviceModal) => void;
  mounted(): void {
    const modal = this.$refs['modal-filter-devices'] as Vue & {
      open: () => void;
      reset: () => void;
    };
    this.setModalFilterDevice(modal);
  }

  openModalFormDevice(): void {
    if (!this.modalFormDeviceRef) return;
    this.modalFormDeviceRef.open();
  }

  openModalFilterDevices(): void {
    if (!this.modalFilterDeviceRef) return;
    this.modalFilterDeviceRef.open();
  }

  resetSearch(): void {
    if (!this.modalFilterDeviceRef) return;
    this.modalFilterDeviceRef.reset();
  }
}
