var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.devices.length > 0)?_c('v-simple-table',{attrs:{"fixed-header":"","height":"450px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Fecha Creación")]),_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Id")]),_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Nombre")]),_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Estado")]),_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Inmueble")]),_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Acciones")])])]),_c('tbody',_vm._l((_vm.devices),function(tempDevice){return _c('tr',{key:tempDevice._id},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatDateLocal(tempDevice.created))+" ")]),_c('td',[_vm._v(_vm._s(tempDevice._id))]),_c('td',{staticClass:"text-center",staticStyle:{"min-width":"150px"}},[_vm._v(" "+_vm._s(tempDevice.name)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.linkStatus[tempDevice.linkStatus])+" ")]),_c('td',{staticClass:"text-center",staticStyle:{"min-width":"170px"}},[(!tempDevice.space)?_c('span',{staticClass:"red--text"},[_vm._v("Sin Inmueble")]):_c('span',[_vm._v(" "+_vm._s(tempDevice.space.name)+" ")])]),_c('td',{staticClass:"text-center",staticStyle:{"min-width":"180px"}},[_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function () { return _vm.openModalUploadFile(tempDevice); }}},[_c('v-icon',[_vm._v("mdi-file-upload")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Subir Archivo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"loading":_vm.target === tempDevice._id,"icon":"","color":"info"},on:{"click":function () { return _vm.downloadFile(tempDevice); }}},[_c('v-icon',[_vm._v("mdi-file-download")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Descargar Archivo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function () { return _vm.openModalDeviceDetails(tempDevice); }}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Detalles")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"pink"},on:{"click":function () { return _vm.openModalDeleteDevice(tempDevice); }}},[_c('v-icon',[_vm._v("mdi-delete-empty-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])])}),0)]},proxy:true}],null,false,1254054970)}):_vm._e(),(_vm.devices.length === 0 && !_vm.searchMode)?_c('div',{staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"height":"65vh"}},[_c('custom-message',{attrs:{"message":"No dispone de ningún dispositivo en este momento"}})],1):_vm._e(),_c('modal-delete-device',{ref:"modal-delete-device"}),_c('modal-device-details',{ref:"modal-device-details"}),_c('modal-upload-file',{ref:"modal-upload-file"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }