import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const device = namespace('device');
@Component
export default class SearchDevice extends Vue {
  /******************* Start - Vuex *******************/
  @device.Getter
  initialLoading!: boolean;
  @device.Getter
  query!: string;
  @device.Mutation
  setQuery!: (query: string) => void;
  @device.Mutation
  setSearchMode!: (val: boolean) => void;
  @device.Action
  loadDevices!: () => Promise<void>;
  @device.Action
  searchEnd!: () => void;
  /******************* End - Vuex *******************/

  get deviceQuery(): string {
    return this.query;
  }

  set deviceQuery(newValue: string) {
    this.setQuery(newValue);
  }

  handleSearch(): void {
    /* if (!this.$refs.form.validate()) return;*/
    if (this.deviceQuery && this.deviceQuery !== '') {
      this.setSearchMode(true);
      this.loadDevices().then();
    }
  }
}
