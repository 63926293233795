var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[(_vm.device)?_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"550px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5",style:({ color: _vm.$vuetify.theme.themes.dark.primary })},[_vm._v(" "+_vm._s('DETALLES DISPOSITIVO')+" ")])]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Id","type":"text","hide-details":"auto","required":""},model:{value:(_vm.device._id),callback:function ($$v) {_vm.$set(_vm.device, "_id", $$v)},expression:"device._id"}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"Private Key","type":"text","hide-details":"auto","required":"","readonly":""},model:{value:(_vm.device.credentials.privateKey),callback:function ($$v) {_vm.$set(_vm.device.credentials, "privateKey", $$v)},expression:"device.credentials.privateKey"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"mt-2"},[_c('div',{staticStyle:{"min-width":"112px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onCopy('private')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-content-copy ")])],1)],1)]}}],null,false,3298382493)},[_c('span',[_vm._v("Copiar Private Key")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.onCopyNative('privateNative')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-content-copy ")])],1)],1)]}}],null,false,2810705419)},[_c('span',[_vm._v("Copiar Private Key Native")])])],1)])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"Certificate Key","type":"text","hide-details":"auto","required":"","readonly":""},model:{value:(_vm.device.credentials.certificatePem),callback:function ($$v) {_vm.$set(_vm.device.credentials, "certificatePem", $$v)},expression:"device.credentials.certificatePem"}})],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"3"}},[_c('v-row',[_c('div',{staticStyle:{"min-width":"112px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onCopy('certificate')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-content-copy ")])],1)],1)]}}],null,false,3827911557)},[_c('span',[_vm._v("Copiar Certificate Key")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.onCopyNative('certificateNative')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-content-copy ")])],1)],1)]}}],null,false,606426643)},[_c('span',[_vm._v("Copiar Certificate Key Native")])])],1)])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Mac Address","type":"text","hide-details":"auto","required":"","readonly":""},model:{value:(_vm.device.macAddress),callback:function ($$v) {_vm.$set(_vm.device, "macAddress", $$v)},expression:"device.macAddress"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":_vm.onCopyMacAddress}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-content-copy ")])],1)],1)]}}],null,false,3953094831)},[_c('span',[_vm._v("Copiar Mac Address")])])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"value":_vm.formatDateLocal(_vm.device.created),"label":"Fecha de Creacion","type":"text","hide-details":"auto","required":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"value":_vm.formatDateLocal(_vm.device.updated),"label":"Fecha de actualizacion","type":"text","hide-details":"auto","required":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre","type":"text","hide-details":"auto","required":"","readonly":""},model:{value:(_vm.device.name),callback:function ($$v) {_vm.$set(_vm.device, "name", $$v)},expression:"device.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Estado","type":"text","hide-details":"auto","required":"","readonly":""},model:{value:(_vm.device.status),callback:function ($$v) {_vm.$set(_vm.device, "status", $$v)},expression:"device.status"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success"},on:{"click":_vm.close}},[_vm._v(" Aceptar ")])],1)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }