








































import { Component, Mixins, Vue } from 'vue-property-decorator';
import FormValidationMixin from '@/app/common/mixins/formValidationMixin';
import { namespace } from 'vuex-class';
const device = namespace('device');
@Component
export default class ModalFilterDevices extends Mixins(FormValidationMixin) {
  dialog = false;
  valid = true;
  linkStatus: string | null = null;
  optionsFilter = [
    { value: 'linked', text: 'Vinculado' },
    { value: 'unlinked', text: 'No Vinculado' },
  ];

  @device.Mutation
  setPaginateLinkStatus!: (val?: string) => void;
  @device.Mutation
  setFilterMode!: (val: boolean) => void;

  @device.Action
  loadDevices!: () => Promise<void>;

  open(): void {
    this.dialog = true;
  }
  close(): void {
    this.dialog = false;
  }

  reset(): void {
    this.linkStatus = null;
    this.setFilterMode(false);
    this.setPaginateLinkStatus(this.linkStatus || undefined);
    this.loadDevices().then();
  }

  filterDevices(): void {
    const form = this.$refs.form as Vue & { validate: () => boolean };
    if (!form.validate()) return;
    this.setPaginateLinkStatus(this.linkStatus || undefined);
    this.setFilterMode(true);
    this.loadDevices().then();
    this.close();
  }
}
