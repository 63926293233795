















































import { Component, Mixins, Vue } from 'vue-property-decorator';
import FormValidationMixin from '@/app/common/mixins/formValidationMixin';
import { Device } from '@/app/models/device';
import { MAX_FILE_SIZE } from '@/app/common/constants/app-constants';
import ModalMixin from '@/app/common/mixins/modalMixin';
import devicesServices from '@/app/services/devices-services';
import { toBase64 } from '@/app/common/functions/to-base64';
@Component
export default class ModalUploadFile extends Mixins(FormValidationMixin, ModalMixin) {
  dialog = false;
  device: Device | null = null;
  loading = false;
  valid = true;
  file: File | null = null;
  base64: string | null = null;

  get computedFileSize(): boolean | string {
    if (!this.file) return false;
    const fileSizeMb = this.file.size;
    return (
      MAX_FILE_SIZE * 1048576 >= fileSizeMb ||
      `El archivo no debe ser mayor a los ${MAX_FILE_SIZE} MB`
    );
  }

  open(device: Device): void {
    this.dialog = true;
    this.device = device;
  }

  uploadDocument(file: File | null): void {
    if (!file) {
      this.reset();
      return;
    }
    this.file = file;
  }

  actionModal(): void {
    const form = this.$refs.form as Vue & { validate: () => boolean };
    if (!form.validate()) return;
    this.loadDocument();
  }

  async loadDocument(): Promise<void> {
    try {
      if (this.device && this.file) {
        this.loading = true;
        const tempBase64 = await toBase64(this.file);
        this.base64 = tempBase64 as string;
        await devicesServices.uploadFile(this.device._id, this.base64);
        this.loading = false;
        this.$toast.success('El archivo se cargo exitosamente');
        this.close();
      }
    } catch (err) {
      this.loading = false;
      if (err.response?.status === 400) {
        this.openModalError(err.response);
        return;
      }
      this.$toast.error('Ups, Ocurrió un error, intente nuevamente');
    }
  }

  reset(): void {
    this.file = null;
    this.base64 = null;
  }

  close(): void {
    this.reset();
    const form = this.$refs.form as Vue & { resetValidation: () => void };
    form.resetValidation();
    this.dialog = false;
  }
}
